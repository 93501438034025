import * as statusMapper from "@/service/error_request_mapper.js";

export class ConsentService {
  constructor(api) {
    this.api = api;
  }


  /** Récupération de l'ensemble des consentements */
  async getAll() {
    return this.api.getAll()
    .then((data) => {
      let consents = data.data;

      consents.sort(function(a, b) {
        return a.digitalName.localeCompare(b.digitalName);
      });
      return consents;
    })
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });    
  }


  /** Récupération d'un seul consentement */
  async getById(entityId) {
    return this.api.getById(entityId)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(entityId);
      converter.convert(error);
    });
  }


  /** Création d'un consentement */
  async create(entity) {
    return this.api.create(entity)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(entity.digitalName);
      converter.add409AlreadyExists(entity.digitalName);
      converter.convert(error);
    });
  }

  /** Mise à jour d'un consentement */
  async update(entity) {
    return this.api.updateConsent(entity)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(entity._id);
      converter.convert(error);
    });
  }

  /** Suppression d'un consentement */
  async delete(entityId) {
    return this.api.delete(entityId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(entityId);
      converter.convert(error);
    });
  }

}