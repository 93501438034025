<template>
  <v-container fluid>
    <TableViewComponent
      title="Table consentements"
      @addItemEvent="onItem()"
      addItemLabel="ajouter un consentement"
      itemLabel="consentement"
      itemsLabel="consentements"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer ce consentement ?"
      :pagination="false"
      :loading="loading"
      :vcols="{ xl: '6', lg: '8', md: '10', sm: '11', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { ConsentService } from "@/service/customers/consent_service.js"

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableConsent",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /** le service de la vue */
      service: null,

      /**les éléments */
      entities: [],
    };
  },
  methods: {
    onItem() {
      this.$router.push(routes.consents.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];

        let entities = [];

        // Récupération de l'ensemble des consentements
        entities = await this.service.getAll();  

        entities.forEach((e) => {
          let entity = JSON.parse(JSON.stringify(e));

          // Convertit les 2 dates en un format lisible
          entity.created = new Date(entity.createdAt).toLocaleString();
          entity.updated = new Date(entity.updatedAt).toLocaleString();

          //action de consultation
          entity.view = () => {
            this.$router.push(
              routes.consents.view.root + "/" + entity.id
            );
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(
              routes.consents.edit.root + "/" + entity.id
            );
          };

          //action de suppression
          entity.delete = async () => {
            try {
              await this.service.delete(entity.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression du consentement : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.entities.push(entity);
        });

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      return  [
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "digitalName",
          default: true,
        },
        {
          text: "Description",
          align: "start",
          sortable: true,
          value: "description",
          default: true,
        },
        {
          text: "Crée le",
          align: "start",
          sortable: true,
          value: "created",
          default: true,
        },
        {
          text: "Mis à jour le",
          align: "start",
          sortable: true,
          value: "updated",
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditDictionary];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditDictionary, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadDictionary];
    },
  },
  mounted() {
    
    // Instanciation des services
    this.service = new ConsentService(this.$api.getConsentApi());

    this.load();
  },
};
</script>

<style>
</style>